import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/teacher/other/colleague.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "colleague",
        data()
        {
            return{
                colleagueList:[],
                colleagueListTotal:0,
                searchContent:"",
                searchColleagueList:[],
                currentPage:1,
                pageSize:7,
                colleague_id:'',
                dialogVisible : false
            }
        },
        created()
        {
            let self=this;
            self.getData()
        },
        methods:
        {

            getData()
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                    search : self.searchContent
                    };
                    nprogressStart();
                    self.$Loading.show();
                    // 获取同事列表请求
                    API.getColleagueList(data)
                        .then((result)=>{
                            nprogressClose();
                            self.$Loading.hide();
                            if(result.code > 0 )
                            {
                                self.colleagueList = result.list
                                self.colleagueListTotal = result.list.length - 0 
                            }
                            // else
                            // {
                            //     Message.error('系统错误')
                            // }
                        })                
                } 
                catch (error) 
                {
                    // Message.error('系统错误') 
                }
            },

            deleteColleague(e)
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        colleague_id : e.id
                    };
                    // 删除同事请求
                    self.$confirm('确认要删除该同事吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        API.delColleague(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                // Message.success('删除成功');
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'success',
                                    displayTime:1500
                                })
                            }
                            // else
                            // {
                            //     Message.error('系统错误');
                            // }
                        })  
                      }).catch(() => {
                        // Message.info('已取消');        
                      });
                } 
                catch (error) 
                {
                    // Message.error('系统错误');
                }
            },

            confirmAdd()
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        colleague_id : self.colleague_id
                    };
                    // 添加同事请求
                    API.addColleague(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.dialogVisible = false;
                                self.colleague_id = '';
                                // Message.success('添加成功');
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'success',
                                    displayTime:1500
                                })
                            }
                            else
                            {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })                
                } 
                catch (error) 
                {
                    // Message.error('系统错误') 
                }
            },

            searchColleague(e)
            {
                try 
                {
                    let self = this;
                    let data = {
                        name: e
                    }
                    if(e)
                    {
                        API.searchColleague(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.searchColleagueList = result.data
                            }
                            else
                            {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })
                    }
                } 
                catch (error) 
                {
                    // Message.error('系统错误')
                }
            },

            handleSearchColleague()
            {
                let self = this;
                self.currentPage = 1;
                self.getData()
            },
        }       
};